/*
 * Div1InputEmail.js: This component renders the input field and the Search button.
 *
 * The input field is used to enter the user's email address.
 * The email address must end with @nike.com or @converse.com.
 * Additionally, as soon as a user starts typing in the input field, Div2, Div3, and Div4 are set to invisible.
 */

import React from 'react';
import isEmailValid from '../../utils/isEmailValid';
import './Div1InputEmail.css';

const Div1InputEmail = ({
                            enteredEmail,
                            setEnteredEmail,
                            Div1SearchButtonClicked,
                            setUserInfo,
                            setDiv2Visible,
                            setDiv3Visible,
                            setDiv4Visible,
                            setDiv4Title,
                            setDiv4Error,
                            setDiv4ButtonVisible,
                            setShowCodeInput,
                            apiProgress,
                            identityProgress,
                            setApiProgress,
                            setIdentityProgress,
                            setIdentityStep,
                            activeStep
                        }) => {

    const handleInputChange = (event) => {
        setEnteredEmail(event.target.value);

        // TODO: The following lines need to be done just once, not every time the input value changes (e.g. while a user is typing).
        setUserInfo({});
        setDiv2Visible(false);
        setDiv3Visible(false);
        setDiv4Visible(false);
        setDiv4Title('');
        setDiv4Error(false);
        setDiv4ButtonVisible(false);
        setShowCodeInput(false);
        setApiProgress(false);
        setIdentityProgress(false);
        setIdentityStep(0);
    };

    const isInputValid = isEmailValid(enteredEmail);

    return (
        <div className={`child-div form-div ${activeStep === 1 ? 'active-step' : ''}`}>
            <input
                type='text'
                placeholder='first.last@nike.com'
                value={enteredEmail}
                onChange={handleInputChange}
            />
            <button
                className={`app-button ${(isInputValid && !apiProgress && !identityProgress) ? 'button-valid' : 'button-invalid'}`}
                disabled={!isInputValid || apiProgress || identityProgress}
                onClick={Div1SearchButtonClicked}
            >Search
            </button>
        </div>
    );
};

export default Div1InputEmail;
