import {useEffect, useState} from 'react';
import {checkMfaStatus, sendMfaChallenge} from '../api/mfa';
import timestamp from '../utils/timestamp';
import {emailToUser} from '../api/email';
import {POLLING_INTERVAL, MAX_RETRY_COUNT} from '../constants/constants';

/**
 * This hook is used when Okta Push MFA is selected. It triggers a series of API to verify a user's identity.
 * @param pushMfaStep       Step in the Push MFA process
 * @param setPushMfaStep    Setter for pushMfaStep
 * @param activeMfaData     Data needed to verify the user's identity
 * @param setDiv4Message    Setter for the message to be displayed in the Div4MessageArea
 *
 * Logic:
 *   If step = 0: Do nothing. Initial state.
 *   If step = 1: Send /trigger_challenge.
 *   If step = 2: Send /verify_challenge every POLLING_INTERVAL ms until user accepts it, API times out, or reaches MAX_RETRY_COUNT.
 *   If step = 3: Send /sendmessage to send email with magic link.
 *   If step = 4: Do nothing. End-to-end successful.
 *   NOTE: For all error cases, set step = 9. This will enable the parent component (MainFrame) to display the message in red and add the "OK" button at the end.
 */
const usePushMfa = (pushMfaStep, setPushMfaStep, activeMfaData, setDiv4Message) => {

    const [sessionId, setSessionId] = useState(null);

    useEffect(() => {

        const oktaPushVerify = async () => {
            try {
                console.log(`mfaVerify: pushMfaStep= ${pushMfaStep}`);

                if (pushMfaStep === 1) { // Send OktaVerify Push
                    setDiv4Message(`Sending OktaVerify Push to ${activeMfaData.factorName}...`);

                    const {
                        data,
                        error
                    } = await sendMfaChallenge(activeMfaData.factorType, activeMfaData.userId, activeMfaData.factorId);

                    data.session_id && setSessionId(data.session_id);
                    setPushMfaStep(error ? 9 : 2);

                } else if (pushMfaStep === 2) { // Poll for the user's response
                    let retryCount = 0;
                    const interval = setInterval(async () => {
                        console.log(timestamp(), `Polling...`);
                        const response = await checkMfaStatus(activeMfaData.factorType, activeMfaData.userId, activeMfaData.factorId, sessionId);

                        if (response.errorMsg) {
                            console.log(`*** Error from checkMfaStatus: ${response.errorMsg}`);
                            if (response.errorMsg.includes('TIMEOUT')) {
                                setDiv4Message('Validation timed out. Please try again');
                            } else {
                                setDiv4Message(response.errorMsg);
                            }

                            clearInterval(interval);
                            setPushMfaStep(9);
                            return;
                        }

                        if (response.status === 'waiting') {
                            setDiv4Message('Waiting for user to accept identity verification...');
                        } else {
                            clearInterval(interval);

                            if (response.status === 'accepted') {
                                setDiv4Message('User has accepted OktaVerify!');
                                setPushMfaStep(3);
                            } else {
                                setDiv4Message(`Unknown status: ${response.status}`);
                                setPushMfaStep(9);
                            }
                            return;
                        }

                        retryCount++;
                        if (retryCount >= MAX_RETRY_COUNT) {    // Safeguard to prevent infinite loop
                            setDiv4Message('MFA check max retry exceeded');
                            clearInterval(interval);
                            setPushMfaStep(9);
                        }
                    }, POLLING_INTERVAL);
                } else if (pushMfaStep === 3) {
                    const {message, error} = await emailToUser(activeMfaData.userEmail, sessionId);
                    setDiv4Message(message || error);
                    setPushMfaStep(error ? 9 : 4);

                }
            } catch (error) {
                setDiv4Message(`Error verifying the user: ${error}`);
                setPushMfaStep(9);
            }
        };

        if (pushMfaStep !== 0) {
            oktaPushVerify();
        }

    }, [pushMfaStep, setPushMfaStep, activeMfaData, setDiv4Message, sessionId]); // end of useEffect

}; // end of usePushMfa

export default usePushMfa;
