import React from 'react';
import {withOktaAuth} from '@okta/okta-react';
import MainFrame from "./components/MainFrame";
// import MainFrame from "./components/MainFrame-exp";
import "./Home.css";
import Header from "./components/Header/Header";

const Home = ({authState}) => {

    if (!authState.isAuthenticated) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            {/*<p className="login-status-bar">You're logged in as {authState.idToken.claims.email}</p>*/}
            <Header username={authState.idToken.claims.email}/>
            <MainFrame />

        </div>
    );
};

export default withOktaAuth(Home);
