import React from 'react';

const Div2Error = ({div2Title, errorMsg, Div2StartOverClicked, activeStep}) => {

    return (
        <div className={`child-div ${activeStep === 2 ? 'active-step' : ''}`}>
            <div className='app-div-subtitle app-error'>{div2Title}</div>
            <div className='app-text app-error'>
                <p>{String(errorMsg)}</p>
            </div>
            <button className='app-button' onClick={Div2StartOverClicked}>Start over</button>
        </div>
    );
};

export default Div2Error;
