import makeRequest from '../api/apiRequest';
import {constructUrl} from './apiConfig';


export const triggerValidateIdentity = async(userId) => {
    console.log(`identity.js:triggerValidateIdentity: userid= ${userId}`);

    const endpoint = '/trigger_id_validation';
    const url = constructUrl(endpoint);

    const response = await makeRequest(
        url, 'POST',
        {"userid": userId},
        {}, {}
    );

    if (response.errorType === 0) {
        return { data: response.data };
    } else {
        return {error: response.errorMessage};
    }
};

export const checkIdVerifyStatus = async (userId, sessionId) => {

    const endpoint = '/get_challenge_value';
    const url = constructUrl(endpoint);

    const response = await makeRequest(
        url, 'POST',
        {"userid": userId,
              "sessionid": sessionId},
        {}, {}
    );

    if (response.errorType === 0) {
        return { data: response.data };
    } else {
        return {error: response.errorMessage};
    }
};
