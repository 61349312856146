/*
 * Login.js: This component calls the signInWithRedirect method from the OktaAuth object.
 */

import React, {useEffect, useState} from 'react';
import {withOktaAuth} from '@okta/okta-react';
import "./Login.css";

const Login = ({oktaAuth}) => {
    console.log("Login+++++")
    const [error, setError] = useState(null);

    useEffect(() => {
        console.log("Login: useEffect ++++");
        const handleLogin = async () => {
            try {
                await oktaAuth.signInWithRedirect();
                console.log("Login: handleLogin: oktaAuth.signInWithRedirect completed")
            } catch (err) {
                setError(err.message);
                console.error(`Login: *** error= ${error}`);
            }
        };

        handleLogin();
    }, [error, oktaAuth]);

    return (
        <div>
            {error && <div className="error">{error}</div>}
        </div>
    );
};

export default withOktaAuth(Login);
