/*
 * This is a wrapper around the axios library. It encapsulates all API requests for all methods and endpoints.
 */
import apiClient from './apiClient';
import handleApiError from "./apiErrorHandler";

/**
 * This function makes an HTTP request using axios.
 * @param endpoint  The URL to which the request is sent
 * @param method    The HTTP method to use
 * @param data      The data to send with the request
 * @param params    The query parameters to send with the request
 * @param headers   The headers to send with the request
 * @returns {error: number, result}  error= 0 if no error, =1 if no data, =2 if errors
 *                  The result is a retrieved object if no error, or an error object if error occurs.
 */
const makeRequest = async (endpoint, method, data = null, params = {}, headers = {}) => {
    try {
        const response = await apiClient({
            url: endpoint,
            method: method,
            data: data,
            params: params,
            headers: {
                ...headers,
            },
        });
        console.log(`makeRequest: response.status= ${response.status}, response.data= ${JSON.stringify(response.data)}`);
        return {errorType: 0, status: response.status, data: response.data};
    } catch (error) {
        return {errorType: 1, errorMessage: handleApiError(error)};
    }
};

export default makeRequest;
