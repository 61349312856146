import {useEffect} from "react";
import {idValidationUrl} from "../config";
import {sendMfaChallenge} from "../api/mfa";

const useVerifySite = (otherMfa, activeMfaData, setDiv4Message, setDiv4Error, setDiv4ButtonVisible) => {

    useEffect(() => {
        if (otherMfa) {
            console.log(`useVerifySite:useEffect:otherMfa=+++`);
            setDiv4Error(false);

            const sendChallenge = async () => {
                setDiv4Message(`Sending challenge to ${activeMfaData.factorName}...`);
                const {
                    data,
                    error
                } = await sendMfaChallenge(activeMfaData.factorType, activeMfaData.userId, activeMfaData.factorId);

                if (error) {
                    if (error.includes('already in a validation group')) {
                        setDiv4Message(`${activeMfaData.userFirstName} already has a password change in progress. Please try again later.`);
                    } else {
                        setDiv4Message(`*** Unexpected error occurred: ${error}`);
                    }
                    setDiv4Error(true);
                    setDiv4ButtonVisible(true);
                    return;
                }

                if (data) {
                    console.log(`useVerifySite: sendChallenge: data= ${JSON.stringify(data)}`);
                    const message = `Please advise ${activeMfaData.userFirstName} to go to this website to complete identity validation:<br>
                            <b>${idValidationUrl}</b>`;

                    setDiv4Message(message);
                    setDiv4ButtonVisible(true);
                }
            };

            sendChallenge();
        }
    }, [otherMfa, activeMfaData, setDiv4Message, setDiv4Error, setDiv4ButtonVisible]);
};

export default useVerifySite;
