import React from 'react';
import './Header.css';

const Header = () => {
    return (
        <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-black justify-content-between">
            <div className="navbar-brand ncss-brand u-uppercase">
                <span className="text-color-white display-3"><i className="g72-swoosh"></i> ServiceDesk-Assisted Password Reset</span>
            </div>
        </nav>
    );
};

export default Header;
