import {oktaIssuer, oktaClientId} from './config';

const oktaConfig = {
    clientId: oktaClientId,
    issuer: oktaIssuer,
    redirectUri: window.location.origin + '/callback',
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
};

export default oktaConfig;
