/*
 * Div3SelectMFA: This component displays a list of available MFA in a dropdown menu and reads a choice.
 *                When "Verify Identity" button is pressed, it sends the selected factor ID to MainFrame in a callback.
 */
import React, {useState} from 'react';
import './Div3Dropdown.css';

/**
 *
 * @param mfaData       An array of MFA factors
 * @param onSelectMfa   Callback with a selected factor ID
 * @param activeStep    The app's active step, just to display the active step in bold
 * @param apiProgress   True if an API call to any Mfa is in progress
 * @param Div3ManagerVerifyClicked  Callback to switch to manager-based verification
 */
const Div3MfaSelection = ({mfaData, onSelectMfa, activeStep, apiProgress, Div3UseManagerVerifyClicked}) => {

    console.log(`Div3MfaSelection+++ apiProgress= ${apiProgress}`);

    const [selectedFactorId, setSelectedFactorId] = useState('');   // State to keep the selected factor object

    // Filter mfaFactor data array by only active factors and not manager type
    const activeMfaData = mfaData.filter(factor => factor.status === 'ACTIVE' && factor.factorType !== 'manager');
    console.log(`Div3MfaSelection: activeMfaData.length= ${activeMfaData.length}`);

    // Called every time a user selects a different factor in the dropdown list
    const handleChange = (event) => {
        const selectedId = event.target.value;    // string
        setSelectedFactorId(selectedId);
        console.log(`Div3MfaDropdownMenu: selectedId= ${selectedId}`); // This is correct
        console.log(`Div3MfaDropdownMenu: selectedFactorId= ${selectedFactorId}`);  // Not updated yet
        // NOTE: selectedFactorId state isn't updated immediately, containing the previously selected value or empty for the first time.
    };

    // Called when a user presses the "Verify Identity" button - set the selection and call back to the parent component (MainFrame).
    const handleButtonPress = () => {
        console.log(`Div3MfaDropdownMenu: handleButtonPress: selectedFactorId= ${selectedFactorId}`)    // Check if this is correct
        onSelectMfa(selectedFactorId);
    }

    // Go through the detected factors and find user-friendly names
    const options = activeMfaData.map((factor, index) => {

        let optionText;

        if (factor.factorType === 'webauthn') {
            // noinspection JSUnresolvedReference
            optionText = `${factor.name}`;
        } else if (factor.factorType === 'push') {
            // noinspection JSUnresolvedReference
            optionText = `OktaVerify Push on ${factor.name}`;
        } else if (factor.factorType === 'call') {
            optionText = `Voice call to ${factor.phoneNumber}`;
        } else if (factor.factorType === 'sms') {
            optionText = `Send SMS to ${factor.phoneNumber}`;
        } else if (factor.factorType === 'external_idp') {
            optionText = `Send email to ${factor.name}`;
        } else if (factor.factorType === 'signed_nonce') {
            optionText = `${factor.platform} ${factor.name}`;
        } else if (factor.factorType === 'token:software:totp') {
            if (factor.provider === 'OKTA') {
                optionText = 'OktaVerify TOTP';
            } else if (factor.provider === 'GOOGLE') {
                optionText = 'Google Authenticator';
            } else {
                optionText = `*** UNKNOWN PROVIDER ${factor.provider}`;
                // NOTE: If this happens, add the factor in this if-elseif block
            }
        } else {
            optionText = `*** UNKNOWN FACTOR ${factor.factorType}`;
            // NOTE: If this happens, add the factor in this if-elseif block
        }

        return (
            <option key={index} value={factor.id}>{optionText}</option>
        );
    });

    return (
        <div className={`child-div ${activeStep === 3 ? 'active-step' : ''}`}>

            <div className='app-div-subtitle'>Identity Verification Required</div>
            <div className='app-text'>
                <p>Select the security method for the user to verify their identity.</p>
            </div>

            <select
                className="dropdown"
                value={selectedFactorId}
                onChange={handleChange}
                disabled={apiProgress || options.length === 0}
            >
                <option value="" disabled>Select a verification method</option>
                {options}
            </select>

            <button
                disabled={!selectedFactorId || apiProgress}
                className={`app-button ${selectedFactorId && !apiProgress ? 'button-valid' : 'button-invalid'}`}
                onClick={handleButtonPress}
            >
                Verify Identity
            </button>

            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <p className='app-text'>
                <span className={apiProgress ? 'disabled-link' : ''}>or use&nbsp;</span>

                <button
                    className={`highlight-link ${apiProgress ? 'disabled-link' : ''}`}
                    onClick={Div3UseManagerVerifyClicked}
                    disabled={apiProgress}
                >
                    Manager-based Identity Verification
                </button>
            </p>
        </div>
    );
};

export default Div3MfaSelection;
