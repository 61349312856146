import React, {useEffect, useState} from 'react';
import './Div3aPassCodeInput.css';

const Div3aPassCodeInput = ({onCodeEntered, activeStep, numDigits}) => {

    const [codes, setCodes] = useState(Array(numDigits).fill(''));
    const [error, setError] = useState('');

    useEffect(() => {
        setCodes(Array(numDigits).fill(''));
    }, [numDigits]);

    const handleChange = (event, index) => {
        const inputValue = event.target.value;
        if (/^\d?$/.test(inputValue)) {
            const newCodes = [...codes];
            newCodes[index] = inputValue;
            setCodes(newCodes);

            // Move focus to the next input box
            if (inputValue && index < (numDigits - 1)) {
                document.getElementById(`authCode${index + 1}`).focus();
            }
            setError('');
        }
    };

    const handleKeyDown = (event, index) => {
        if (event.key === 'Backspace' && !codes[index] && index > 0) {
            document.getElementById(`authCode${index - 1}`).focus();
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (codes.every((code) => code !== '')) {
            const code = codes.join('');
            console.log('Code submitted:', code);
            onCodeEntered(code);      // This will call back to MainFrame which has other data to send with code
        } else {
            setError('Enter the code from the selected MFA');
        }
    };

    const handleReset = () => {
        setCodes(Array(numDigits).fill(''));
        setError('');
    };

    return (
        <div className={`child-div ${activeStep === 3 ? 'active-step' : ''}`}>
            <div className='app-div-subtitle'>Enter Code from the Selected MFA</div>
            <form onSubmit={handleSubmit} onReset={handleReset}>
                <div className="auth-code-inputs">
                    {codes.map((code, index) => (
                        <input
                            key={index}
                            type="text"
                            id={`authCode${index}`}
                            name={`authCode${index}`}
                            value={code}
                            onChange={(e) => handleChange(e, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            maxLength="1"
                            className="auth-code-input"
                        />
                    ))}
                </div>
                {error && <p style={{color: 'red'}}>{error}</p>}
                <button type="submit" className='app-button button-under'>Submit</button>
                <button type="reset" className='app-button button-under'>Clear</button>
            </form>
        </div>
    );

};

export default Div3aPassCodeInput;
