/*
 * handleApiError: Handle API errors.
 */

const handleApiError = (error) => {
    if (error.response) {      // API error with an error object, e.g. 404 User not found
        console.log(`handleApiError: error.response.data= ${JSON.stringify(error.response.data)}`);
        return error.response.data.message; // e.g. "User not found"
    }
    else {      // API error with no error object, e.g. Network error, CORS policy error
        console.log(`handleApiError: No error response object: error= ${error}`);
        console.log(`handleApiError: No error response object: error.message= ${error.message}`);
        return error.message;
    }
};

export default handleApiError;
