import {sendPasscode} from "../api/mfa";

const useSubmitCode = (code, activeMfaData, voiceSmsSessionId = '') => {

    const submitCode = async (code) => {
        console.log(`useSubmitCode: submitCode+++ code= ${code}, voiceSmsSessionId= ${voiceSmsSessionId}`);
        let response;

        response = await sendPasscode(activeMfaData.userId, activeMfaData.factorType, activeMfaData.factorId, code, voiceSmsSessionId);
        console.log(`useSubmitCode: submitCode: response= ${JSON.stringify(response)}`);

        if (response.errorMsg) {
            console.log(`useSubmitCode: submitCode: Error from sendPasscode: ${response.errorMsg}`);
            return {error: response.errorMsg};
        }

        if (response.sessionId) {
            const sessionId = response.sessionId;
            console.log(`useSubmitCode: submitCode: sessionId= ${sessionId}`);
            return {sessionId: sessionId};
        }
    };

    return {submitCode};
};

export default useSubmitCode;

