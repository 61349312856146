import { emailToUser } from '../api/email';

const useEmailUser = (sessionId, activeMfaData, setDiv4Message, setDiv4Error, setDiv4ButtonVisible) => {

    const emailUserIdVerify = async () => {
        console.log(`useSendEmail: submitCode success! sessionId= ${sessionId}`);
        console.log(`useSendEmail: Setting apiProgress to true...`);
        setDiv4Message('Code accepted');

        // Send email to the end user
        setDiv4Message(`Emailing magic link to ${activeMfaData.userFirstName}...`);
        const { message, error } = await emailToUser(activeMfaData.userEmail, sessionId);
        setDiv4Error(error);
        setDiv4Message(message || error);
        setDiv4ButtonVisible(true);
    };

    return { emailUserIdVerify };
};

export default useEmailUser;
