// noinspection JSUnresolvedReference

/*
 * findUserByEmail: This function will search a user by email address and returns the object.
 *
 * If the user is found, it will return the user's information.
 * If the user isn't found or an error occurs, it will return an error message.
 */
import makeRequest from '../api/apiRequest';
import {constructUrl} from './apiConfig';

const findUserByEmail = async (email) => {

    const endpoint = `/user?user=${email}`;
    const url = constructUrl(endpoint);
    const response = await makeRequest(url, 'GET', null, {}, {});

    if (response.errorType === 0) {   // Request success and data exists
        return {
            userInfo: response.data,
            errorMsg: null,
        };
    } else {
        console.log(`findUserByEmail: response.errorMessage= ${JSON.stringify(response.errorMessage)}`);
        return {
            userInfo: null,
            errorMsg: response.errorMessage,
        };
    }

};

export default findUserByEmail;
