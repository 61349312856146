/*
 * Div4MessageArea: This component renders the status messages while APIs or user interactions are in progress.
 */
import React from 'react';
import Spinner from './Spinner';

const Div4MessageArea = ({
                             message,
                             div4Title = '',
                             div4Error,
                             isDiv4ButtonVisible,
                             Div4OkClicked,
                             activeStep
                         }) => {

    console.log(`Div4MessageArea: div4Title= ${div4Title}, message= ${message}`);
    return (
        <div className={`child-div ${activeStep === 4 ? 'active-step' : ''}`}>
            <div className={`app-div-subtitle ${div4Error ? 'app-error' : ''}`}>
                <div className='title-container'>
                    {div4Title}
                    {div4Title.toLowerCase().includes('in progress') && <Spinner />}
                </div>
            </div>

            <p className={`${div4Error ? 'app-text app-error' : 'app-text'}`}
               dangerouslySetInnerHTML={{__html: message}}/>
                {
                    isDiv4ButtonVisible && (
                        <button className='app-button' onClick={Div4OkClicked}>OK</button>
                    )
                }
        </div>
    );
};

export default Div4MessageArea;
