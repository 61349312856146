// noinspection JSUnresolvedReference

/*
 * managers: This file contains API requests pertaining to a user's manager(s).
 *
 * If the user is found, it will return the user's information.
 * If the user isn't found or an error occurs, it will return an error message.
 */
import makeRequest from '../api/apiRequest';
import {constructUrl} from './apiConfig';

/**
 * getManagers: This function will search a user's manager(s) by email address and returns the object.
 * @param   {string} email   User's email address
 * @returns {Promise<{userInfo, errorMsg: null}|{userInfo: null, errorMsg}>}
 */
export const getManagers = async (email) => {

    const endpoint = `/managers?user=${email}`;
    const url = constructUrl(endpoint);
    const response = await makeRequest(url, 'GET', null, {}, {});

    if (response.errorType === 0) {   // Request success and data exists
        return {
            managerInfo: response.data,
            errorMsg: null,
        };
    } else {
        console.log(`findUserByEmail: response.errorMessage= ${JSON.stringify(response.errorMessage)}`);
        return {
            managerInfo: null,
            errorMsg: response.errorMessage,
        };
    }

};

