/*
 * Functions to send email.
 */
import makeRequest from '../api/apiRequest';
import {constructUrl} from './apiConfig';

export const emailToUser = async (userEmail, sessionId) => {

    const endpoint = '/sendmessage';
    const url = constructUrl(endpoint);
    const response = await makeRequest(
        url, 'POST',
        {"user": userEmail, "sessionid": sessionId},
        {}, {}
    );
    console.log(`emailToUser: response.errorType= ${response.errorType}`);

    if (response.errorType === 0) {
        return {message: response.data.message};
    } else {
        return {error: response.errorMessage};
    }
};

const emailToManager = async (managerEmail, userId, flow) => {

    const endpoint = '/manager_approval';
    const url = constructUrl(endpoint);
    console.log(`emailToManager: url= ${url}, manager_email= ${managerEmail}, userid= ${userId}, flow= ${flow}`);
    const response = await makeRequest(
        url, 'POST',
        {"manager_email": managerEmail, "userid": userId, "flow": flow},
        {}, {}
    );
    console.log(`emailToManager: response.errorType= ${response.errorType}`);

    if (response.errorType === 0) {
        return { message: response.data.message };
    } else {
        return {error: response.errorMessage};
    }
};

export const emailToManagerIdVerify = async (managerEmail, userId) => {
    return await emailToManager(managerEmail, userId, 'password_reset');
};

export const emailToManager2ndEmail = async (managerEmail, userId) => {
    return await emailToManager(managerEmail, userId, 'add_ext_email');

};

