/**
 * This is a wrapper around the axios library. It creates an axios instance and appends headers based on requests.
 */
import axios from 'axios';
import {getToken} from '../utils/auth';


const apiClient = axios.create({
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
});

apiClient.interceptors.request.use(
    (config) => {
        const token = getToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default apiClient;
