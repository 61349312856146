import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Navigate } from 'react-router-dom';

const SecureRoute = ({ children }) => {
    const { authState } = useOktaAuth();

    if (!authState || !authState.isAuthenticated) {
        return <Navigate to="/login" />;
    }

    return children;
}

export default SecureRoute;
