import {useEffect} from "react";
import {sendMfaChallenge} from "../api/mfa";

const useVoiceSms = (telecomMfa, activeMfaData, setDiv4Message, setShowCodeInput, setVoiceSmsSessionId) => {

    useEffect(() => {
        const voiceSms = async () => {
            if (telecomMfa) {
                setShowCodeInput(false);
                setDiv4Message(`Sending code via ${activeMfaData.factorType}...`);
                const response = await sendMfaChallenge(activeMfaData.factorType, activeMfaData.userId, activeMfaData.factorId);
                if (response.data) {
                    console.log(`useVoiceSms: data= ${JSON.stringify(response.data)}`);
                    setVoiceSmsSessionId(response.data.session_id);
                    setShowCodeInput(true);
                    setDiv4Message('Code has been sent');
                }
                if (response.error) {
                    console.log(`useVoiceSms: Error: ${response.error}`);
                    setVoiceSmsSessionId('');
                    setShowCodeInput(false);
                    setDiv4Message(`Error occurred: ${response.error}`);
                }
            }
        };

        voiceSms();

    }, [telecomMfa, activeMfaData, setDiv4Message, setShowCodeInput, setVoiceSmsSessionId]);

};

export default useVoiceSms;
