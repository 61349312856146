import {emailToManagerIdVerify, emailToManager2ndEmail} from '../api/email';

const useEmailManager = (workflow, selectedManager, userInfo, setDiv4Message, setDiv4Error, setDiv4ButtonVisible) => {

    const emailManager = async () => {
        setDiv4Error(false);
        setDiv4ButtonVisible(false);

        const action = workflow === 2 ? 'verify' : 'add the personal email of';
        console.log(`useEmailManager:emailManager: Emailing ${selectedManager.managerName} to ${action} ${userInfo.firstName}...`);
        setDiv4Message(`Emailing ${selectedManager.managerName} to ${action} ${userInfo.firstName}...`);

        const emailFunction = workflow === 2 ? emailToManagerIdVerify : emailToManager2ndEmail;
        const {message, error} = await emailFunction(selectedManager.managerEmail, userInfo.okta.id);
        setDiv4Error(error);
        setDiv4Message(message || error);
        setDiv4ButtonVisible(true);
    };

    return {emailManager};
};

export default useEmailManager;
