import {constructUrl} from "./apiConfig";
import makeRequest from "./apiRequest";

/**
 * Send a 6-digit code to backend
 * @param userId        The user's ID returned by user search
 * @param factorType    The type of the selected MFA factor
 * @param factorId      The ID of the selected MFA factor
 * @param passcode      The 6-digit code from the app associated with the selected MFA factor
 * @param sessionId     Only Voice/SMS: The session ID returned by /trigger_challenge
 */
export const sendPasscode = async (userId, factorType, factorId, passcode, sessionId) => {
    console.log(`mfa: sendPasscode+++ userId= ${userId}, factorType= ${factorType}, factorId= ${factorId}, passcode= ${passcode}, sessionId= ${sessionId}`);

    const endpoint = '/verify_response';
    const url = constructUrl(endpoint);
    const response = await makeRequest(
        url, 'POST',
        {"type": factorType, "factorid": factorId, "userid": userId, "passcode": passcode, "sessionid": sessionId},
        {}, {}
    );
    console.log(`mfa: sendPasscode--- response= ${JSON.stringify(response)}`);
    return {
        sessionId: response.errorType === 0 ? response.data.session_id : null,
        errorMsg: response.errorType !== 0 ? response.errorMessage : undefined
    };
};

/*
 * Send Okta Push
 */
export const sendMfaChallenge = async (factorType, userId, factorId) => {
    console.log(`mfa: sendMfaChallenge: factorType= ${factorType}, userId= ${userId}, factorId= ${factorId}`);

    const endpoint = '/trigger_challenge';
    const url = constructUrl(endpoint);
    const response = await makeRequest(
        url, 'POST',
        {"type": factorType, "factorid": factorId, "userid": userId},
        {}, {}
    );
    console.log(`mfa: sendMfaChallenge: response= ${JSON.stringify(response)}`);

    return {
        data: response.errorType === 0 ? response.data : null,
        error: response.errorType !== 0 ? response.errorMessage : undefined
    }
};

/*
 * Get MFA status from the Push
 */
export const checkMfaStatus = async (factorType, userId, factorId, sessionId) => {
    console.log(`mfa: checkMfaStatus+++ factorType= ${factorType}, userId= ${userId}, factorId= ${factorId}, sessionId= ${sessionId}`);

    const endpoint = '/verify_response';
    const url = constructUrl(endpoint);
    const response = await makeRequest(
        url, 'POST',
        {"type": factorType, "factorid": factorId, "userid": userId, "sessionid": sessionId},
        {}, {}
    );

    if (response.errorType !== 0) {
        if (response.errorMessage.includes('Invalid session')) {
            return {
                status: 'timeout',
                errorMsg: 'Timed out waiting for user response.',
            };
        } else {
            return {
                status: 'error',
                errorMsg: response.errorMessage,
            };
        }
    }

    if (response.data.next.includes('sendmessage')) {
        return {
            status: 'accepted'
        };
    } else if (response.data.next.includes('verify_response')) {
        return {
            status: 'waiting'
        };
    } else {
        return {
            status: 'error',
            errorMsg: `*** Unexpected response ${response.data.next}`
        };
    }

};
