/*
 * Div2Searching: This component displays the API fetch status.
 */
import React from 'react';

const Div2Searching = ({div2Title, activeStep}) => {

    return (
        <div className={`child-div ${activeStep === 2 ? 'active-step' : ''}`}>
            <div className='app-div-subtitle'>{div2Title}</div>
        </div>
    );
};

export default Div2Searching;
