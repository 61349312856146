import {useEffect, useState} from 'react';
import timestamp from '../utils/timestamp';
import {POLLING_INTERVAL, MAX_RETRY_COUNT} from '../constants/constants';
import {checkIdVerifyStatus, triggerValidateIdentity} from "../api/identity";
import {idValidationUrl} from '../config';

/**
 * This hook is used when "Validate Identity" is selected. It triggers a series of API to verify a user's identity.
 * @param identityStep       Step in the Push MFA process
 * @param setIdentityStep      Setter for identityStep
 * @param userInfo           User data returned by email lookup
 * @param setDiv4Message     Setter for the message to be displayed in the Div4MessageArea
 * @param setDiv4Title       Setter for the title to be displayed in the Div4MessageArea
 * @param setDiv4Error       Setter for the error flag to be displayed in the Div4MessageArea
 *
 * Logic:
 *   If step = 0: Do nothing. Initial state.
 *   If step = 1: POST /trigger_id_validation.
 *   If step = 2: POST /get_challenge_value every POLLING_INTERVAL until user is validated or reaches MAX_RETRY_COUNT.
 *   If step = 9: Error occurred.
 */
const useValidateIdentity = (identityStep, setIdentityStep, userInfo, setDiv4Message, setDiv4Title, setDiv4Error) => {

    const [sessionId, setSessionId] = useState(null);

    useEffect(() => {

        const validateIdentity = async () => {

            try {
                console.log(`useValidateIdentity: identityStep= ${identityStep}`);

                if (identityStep === 1) { // trigger identity validation
                    setDiv4Title('Identity Validation in Progress ');
                    const message = `<span style="color: red;">Don't close this window</span><br>
                        Starting the identity validation session...`;
                    setDiv4Message(message);

                    const response = await triggerValidateIdentity(userInfo.okta.id);
                    console.log(`useValidateIdentity: response= ${JSON.stringify(response)}`);

                    if (response.error) {
                        setDiv4Message(`Error starting identity validation: ${response.error}`);
                        setDiv4Error(true);
                        setIdentityStep(9);
                    } else if (response?.data?.session_id) {
                        setSessionId(response.data.session_id); // NOTE: This doesn't take effect immediately, but it is set when we use it in identityStep 2
                        console.log(`Identity validation session has started: response.data.session_id= ${response.data.session_id}`);
                        setDiv4Message(`Identity validation session has started: response.data.session_id= ${response.data.session_id}`);
                        const message = `<span style="color: red;">Don't close this window</span><br>
                            Please advise ${userInfo.firstName} to go to this website to complete identity validation:<br>
                            <b>${idValidationUrl}</b>`;
                        setDiv4Message(message);
                        setIdentityStep(2);
                    }
                } else if (identityStep === 2) { // Poll for verify response
                    let retryCount = 0;
                    const interval = setInterval(async () => {
                        console.log(timestamp(), `Polling for user ${userInfo.okta.id} session ${sessionId}...`);
                        const response = await checkIdVerifyStatus(userInfo.okta.id, sessionId);

                        if (response.error) {
                            console.log(`*** Error from checkIdVerifyStatus: ${response.errorMsg}`);

                            // Any error from backend will stop the process
                            setDiv4Title('ERROR occurred!')
                            setDiv4Message(response.errorMsg);
                            setDiv4Error(true);
                            clearInterval(interval);
                            setIdentityStep(9);
                            return;
                        }

                        // NOTE: challenge_result is PENDING until user enters the 4-digit code
                        if (response.data.challenge_result === 'PENDING') {
                            if (response.data.challenge_value === null) {
                                console.log(`PENDING: Waiting for user to validate identity...`);
                            } else {  // if there's something in challenge_value
                                console.log(`User has validated the first step!`);
                                console.log(`challenge_value= ${response.data.challenge_value}`);
                                const message = `<span style="color: red;">Don't close this window</span><br>
                                    Please pass this code to ${userInfo.firstName} to complete identity validation:<br>
                                    <b>${response.data.challenge_value}</b>`;
                                setDiv4Message(message);
                            }
                        }
                        // NOTE: SUCCESS indicates that user has entered the correct 4-digit code
                        else if (response.data.challenge_result === 'SUCCESS') {
                            clearInterval(interval);
                            setDiv4Title('Identity Validation Success!');
                            console.log(`User has validated the second step!`);
                            setDiv4Message(`${userInfo.firstName}'s identity has been successfully validated!`);
                            setIdentityStep(3);
                            setSessionId(null);
                            return;
                        }
                        // Any other challenge_result is unknown
                        else {
                            clearInterval(interval);
                            console.log(`*** Unknown challenge_result: ${response.data.challenge_result}`);
                            setDiv4Message(`Unknown status: ${response.data.challenge_result}`);
                            setIdentityStep(9);
                            return;
                        }

                        retryCount++;
                        if (retryCount >= MAX_RETRY_COUNT) {    // Safeguard to prevent infinite loop
                            clearInterval(interval);
                            setIdentityStep(9);
                            console.log(`Max retry exceeded`);
                            setDiv4Error(true);
                            setDiv4Title('Timed out!')
                            setDiv4Message(`User validation timed out. Please try again later.`);
                        }
                    }, POLLING_INTERVAL);
                }
            } catch (error) {
                setDiv4Error(true);
                setDiv4Title('ERROR occurred!')
                setDiv4Message(`Error verifying the user: ${error}`);
                setIdentityStep(9);
            }
        };

        if (identityStep !== 0) {
            validateIdentity();
        }
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [identityStep, userInfo, sessionId]); // end of useEffect

};

export default useValidateIdentity;
