/*
 * Div2UserFound: This component displays the user information.
 *
 * It receives the entire userInfo returned by GET /user?user=${email}.
 * This component/UI can decide what to display.
 */
import React from 'react';
import './Div2UserInfo.css';

const Div2UserFound = ({
                           div2Title,
                           userInfo,
                           activeStep,
                           apiProgress,
                           identityProgress,
                           div2ButtonLabel,
                           handleDiv2ButtonPress,
                           handleValidateIdButtonPress
                       }) => {


    if (!userInfo) {
        return null;    // Don't render anything if userInfo isn't loaded yet
    }

    if (div2ButtonLabel === '') {   // Don't render anything if the button label is empty
        return null;
    }

    return (
        <div className={`child-div ${activeStep === 2 ? 'active-step' : ''}`}>
            <div className='app-div-subtitle'>{div2Title}</div>
            <div className='user-info-container app-text'>
                <div className="user-info"><strong>Name: </strong>{userInfo.firstName} {userInfo.lastName}</div>
                <div className="user-info"><strong>Type: </strong>{userInfo.job?.employeeType}</div>
                <div className="user-info">
                    <strong>Location: </strong>{userInfo.location.city}, {userInfo.location.state}, {userInfo.location.countryCode}
                </div>
                <div className="user-info"><strong>Building: </strong>{userInfo.location.officeLocation}</div>
                <div className="user-info"><strong>Title: </strong>{userInfo.job?.title}</div>
                <div className="user-info"><strong>Department: </strong>{userInfo.job?.department}</div>
                <div className="user-info"><strong>Email: </strong> {userInfo.email}</div>
                <div className="user-info"><strong>Personal email: </strong>{userInfo.okta?.secondEmail}</div>
            </div>

            <button
                className={`app-button button-under  ${(apiProgress || identityProgress) ? 'button-invalid' : 'button-valid'}`}
                disabled={apiProgress || identityProgress}
                onClick={handleValidateIdButtonPress}
            >Validate Identity
            </button>

            <button
                className={`app-button button-under ${(apiProgress || identityProgress) ? 'button-invalid' : 'button-valid'}`}
                disabled={apiProgress || identityProgress}
                onClick={handleDiv2ButtonPress}
            >{div2ButtonLabel}
            </button>

        </div>
    );
};

export default Div2UserFound;
