import React, {useEffect, useState} from 'react';
import {getManagers} from '../../api/managers';
import './Div3Dropdown.css';

const Div3ManagerSelection = (
    {
        shouldFetchManager,
        enteredEmail,
        activeStep,
        onSelectManager,
        title,
        buttonLabel,
        action
    }) => {

    const [managerData, setManagerData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedManager, setSelectedManager] = useState('');

    console.log(`Div3ManagerSelection: activeStep: ${activeStep}`);

    useEffect(() => {
        if (shouldFetchManager) {
            console.log(`Div3ManagerSelection:useEffect: Fetching manager data for ${enteredEmail}...`);

            const getManagerData = async () => {
                setLoading(true);
                const {managerInfo, errorMsg} = await getManagers(enteredEmail)
                if (managerInfo) {
                    // console.log(`Div3ManagerSelection:useEffect:getManagerData: managerInfo= ${JSON.stringify(managerInfo)}`);
                    console.log(`Div3ManagerSelection:useEffect:getManagerData: managerInfo.manager_chain.length: ${managerInfo.manager_chain.length}`);

                    // NOTE: Backend returns an array of 3 objects in manager_chain, with data fields null if there aren't 3 managers
                    // NOTE: Extract only the objects with non-null name and email fields
                    const filteredManagerInfo = managerInfo.manager_chain.filter(manager => manager.name != null && manager.email !== null);
                    console.log(`Div3ManagerSelection:useEffect:getManagerData: filteredManagerInfo.length: ${filteredManagerInfo.length}`);
                    setManagerData(filteredManagerInfo);
                } else if (errorMsg) {
                    console.log(`Div3ManagerSelection:useEffect:getManagerData: *** ERROR: ${errorMsg}`);
                    setError(errorMsg);
                }

                setLoading(false);
            };

            getManagerData();
        }
    }, [shouldFetchManager, enteredEmail]);

    const handleDropdownChange = (e) => {
        console.log(`Div3ManagerSelection:handleDropdownChange: Setting ${e.target.value} to selectedManager...`);
        setSelectedManager(e.target.value);
        console.log(`Div3ManagerSelection:handleDropdownChange: selectedManager= ${selectedManager}`);
    };

    const handleButtonPress = () => {
        console.log(`handleButtonPress+++`);
        if (selectedManager) {
            const [selectedName, selectedEmail] = selectedManager.split('|');
            onSelectManager({managerName: selectedName, managerEmail: selectedEmail});
        }
    };

    return (
        <div className={`child-div ${activeStep === 3 ? 'active-step' : ''}`}>

            {loading ? (
                <div className='app-div-subtitle'>Searching managers...</div>
            ) : error ? (
                <div className='app-div-subtitle app-error'>Error: {error}</div>
            ) : (
                <>
                    <div className='app-div-subtitle'>{title}</div>
                    <div className='app-text'>
                        <p>{action} must be completed by user's manager.</p>
                    </div>

                    <select
                        className="dropdown"
                        value={selectedManager}
                        onChange={handleDropdownChange}
                    >
                        <option value="" disabled>Select a manager</option>
                        {managerData.map((manager, index) => (
                            <option key={index} value={`${manager.name}|${manager.email}`}>
                                [{index}] {manager.name}
                            </option>
                        ))}
                    </select>

                    <button
                        disabled={!selectedManager}
                        className={`app-button ${selectedManager ? 'button-valid' : 'button-invalid'}`}
                        onClick={handleButtonPress}
                    >
                        {buttonLabel}
                    </button>
                </>
            )
            }
        </div>
    );
}

export default Div3ManagerSelection;
